
import React, { useEffect, useState } from 'react';
import { getCourseById } from './api/CourseApi';
import { login, verifyToken } from './api/AuthApi';
import CommonNavbar from './component/common/Navbar';
import VimeoPlayerDuration from './component/structural/VimeoPlayerDuration';
import { getCourseLessonByUserId, getCourseListData } from './api/CourseApi';
import parse from 'html-react-parser';
import { useParams, Link } from 'react-router-dom';
import { Container, Col, Row, Modal, Button } from 'react-bootstrap';
import { useAuth } from './context/AuthContext';
import Footer from './component/common/Footer';
import { useNavigate } from 'react-router-dom';
import { ToastComponent } from './component/common/ToastComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';


const CourseDetail = () => {
    const { id } = useParams();


    const [Data, setData] = useState([]);
    const [chapterId, setChapterId] = useState('');


    const [CourseLessonPosition, setCourseLessonPosition] = useState([]);
    const { login: authLogin, verifyToken: authVerifyToken, userDetail, token } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    console.log("userDetail", userDetail);



    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [toastText, setToastText] = useState({
        status: "danger",
        text: "error"
    });


    const handleChange = (value) => {
        setShow(value);
    };


    useEffect(() => {
        if (userDetail) {
            const fetchData = async () => {
                try {
                    const data = await getCourseLessonByUserId(userDetail.id, token);
                    setCourseLessonPosition(data);
                } catch (error) {
                    console.log("error", error);
                }
            };

            fetchData();
        }
    }, [userDetail ? userDetail.id : ``]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCourseById(id);
                setData(data);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, [id]);

    const name = Data?.name;
    const [readMore, setReadMore] = useState(false);

    const matchWithCourseLesson = (id) => {
        const foundLesson = CourseLessonPosition.find(data => data.course_lesson_id === id);
        if (foundLesson) {
            return true;
        } else {
            return false;
        }
    }

    const chapterStatus = (id) => {
        const findChapter = Data?.course_chapters.find(data => data.id === id);

        if (!findChapter) {
            console.log("Chapter not found");
            return;
        }

        const courseLesson = findChapter.course_lessons;

        const newArray = courseLesson
            .filter(item1 => CourseLessonPosition.some(item2 => item2.course_lesson_id === item1.id))
            .map(item1 => {
                const matchingItem = CourseLessonPosition.find(item2 => item2.course_lesson_id === item1.id);
                return { ...item1, ...(matchingItem || {}) };
            });

        if (courseLesson.length === newArray.length) {
            return true;
        } else {
            return false;
        }
    };

    const [latestCourses, setLatestCourses] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCourseListData("latest=4");
                setLatestCourses(data);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    console.log("latestCourses", latestCourses);

    const frontBaseUrl = process.env.REACT_APP_BASE_URL;

    const [loginModal, setLoginModal] = useState(false);
    const [courseLessonPath, setCourseLessonPath] = useState('');

    const handleClose = () => {
        setLoginModal(false);
        setCourseLessonPath()
    }
    const handleShow = (link) => {
        setLoginModal(true);
        setCourseLessonPath(link);
    }

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);

                const token = await login(values.email, values.password);
                authLogin(token);

                const data = await verifyToken(token);
                authVerifyToken(data.user);

                if (data?.status === true) {
                    setShow(true);
                    setToastText({
                        status: 'success',
                        text: 'User Logged in successfully',
                    });
                    setMessage('User Logged in successfully');
                    handleClose();
                    setTimeout(() => {
                        navigate(courseLessonPath);
                    }, 2000);
                } else {
                    setShow(true);
                    setMessage(data?.message);
                    setToastText({
                        status: 'danger',
                        text: data?.message,
                    });
                }
            } catch (error) {
                console.error('Login failed:', error.message);
                setShow(true);
                setMessage('Login failed');
                setToastText({
                    status: 'danger',
                    text: 'Login failed',
                });
            } finally {
                setSubmitting(false);
            }
        },
    });

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const token = await login(email, password);
            authLogin(token);

            const data = await verifyToken(token);
            authVerifyToken(data.user);

            if (data?.status == true) {
                setShow(true);
                setToastText({
                    status: "success",
                    text: "User Logged in successfully"
                })
                setMessage("User Logged in successfully");
                handleClose();
                setTimeout(() => {
                    navigate(courseLessonPath);
                }, 2000);
            }
            else {
                setShow(true);
                setMessage(data?.message);
                setToastText({
                    status: "danger",
                    text: data?.message
                })
            }

        } catch (error) {
            console.error('Login failed:', error.message);
            setShow(true);
            setMessage("Login failed");
            setToastText({
                status: "danger",
                text: "Login failed"
            })
        }
    };

    return (
        <>
            {Data && (
                <>
                    <CommonNavbar />
                    <ToastComponent bg={toastText.status} title={toastText.text} msg={message} show={show} onChange={handleChange} />
                    <Modal show={loginModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Please Login To Watch Video</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={formik.handleSubmit}>
                            <Modal.Body>
                                <p className='mt-3'>
                                    Entra nel tuo account per accedere al tuo profilo, alla cronologia e a eventuali pagine private a cui ti è stato consentito l’accesso.
                                </p>
                                <div className="form-group mb-3">
                                    <input
                                        type="email"
                                        className={`form-control mt-4 ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                        placeholder="Email"
                                        {...formik.getFieldProps('email')}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className="invalid-feedback">{formik.errors.email}</div>
                                    )}
                                    <input
                                        type="password"
                                        className={`form-control mt-4 ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                                        placeholder="Password"
                                        {...formik.getFieldProps('password')}
                                    />
                                    {formik.touched.password && formik.errors.password && (
                                        <div className="invalid-feedback">{formik.errors.password}</div>
                                    )}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
                                    {formik.isSubmitting ? 'Logging in...' : 'Login'}
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                    {/* <Modal show={loginModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Please Login To Watch Video</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={handleLogin}>
                            <Modal.Body>
                                <p className='mt-3'>
                                    Entra nel tuo account per accedere al tuo profilo, alla cronologia e a eventuali pagine private a cui ti è stato consentito l’accesso.
                                </p>
                                <div className="form-group mb-3">
                                    <input type="email" className="form-control mt-4" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <input type="password" className="form-control mt-4" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" type="submit">
                                    Login
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal> */}

                    <div className='course-detail'>
                        <div className='header'>
                            <Container>
                                <Row>
                                    <Col lg={8} sm={12} xs={12} className='my-auto'>
                                        <h2 className='mb-4'>{name}</h2>
                                        {Data.short_description !== undefined && (
                                            <p>
                                                {Data.short_description}
                                            </p>
                                        )}
                                    </Col>
                                    <Col lg={4} sm={12} xs={12}>
                                        <div className='course-lesson-image' >
                                            <img src={`${process.env.REACT_APP_API_BASE_URL}courselesson/download/${Data.image}`} alt='course-data' className='rounded-0' />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className='main-content'>
                            <Container>
                                <Row>
                                    <Col lg={8} sm={12} xs={12}>
                                        {Data.course_learn !== undefined && Data.course_learn.length !== 0 && (
                                            <div className='learn-section mt-5 mb-5 border border-secondary p-4'>
                                                <h4 className='mb-4 fw-bold'>{`What you'll learn`}</h4>
                                                <Row>
                                                    {Data.course_learn.map((obj, index) =>
                                                        <Col lg={6} sm={12} xs={12} key={`course_learn_${index}`}>
                                                            <Row>
                                                                <Col lg={1}>
                                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                                </Col>
                                                                <Col lg={11}>
                                                                    {obj.name !== undefined && (
                                                                        <p>
                                                                            {obj.name}
                                                                        </p>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </div>
                                        )}


                                        <div className='course-section'>
                                            <h4 className='mb-4 fw-bold'>{`Course Section`}</h4>
                                            <span className='text-secondary'>
                                                {Data.course_chapter_count !== undefined && (
                                                    <>
                                                        {`${Data.course_chapter_count} chapters`}
                                                        {` . `}
                                                    </>
                                                )}
                                                {Data.course_lesson_count !== undefined && `${Data.course_lesson_count} lessons`}
                                            </span>

                                            <div className='mt-3 mb-3' style={{ backgroundColor: "#f7f9fa" }}>
                                                {Data.course_chapters !== undefined && (
                                                    Data.course_chapters.map((chapter, index) => (
                                                        <div key={index} className={`border  border-secondary ${index === 0 ? '' : 'border-top-0'}`}>
                                                            <div className='course-chapters d-flex p-3'>
                                                                {chapterStatus(chapter.id) ?
                                                                    <span>
                                                                        <i className="fa fa-check me-2" aria-hidden="true"></i>
                                                                    </span>
                                                                    : <span>
                                                                        <i className="fa fa-check me-2 empty-circle" aria-hidden="true"></i>
                                                                    </span>}

                                                                <h5 className='me-4' onClick={() => setChapterId(chapter.id)} style={{ cursor: "pointer", fontSize: "20px", fontWeight: "600 !important" }}>
                                                                    {`Ch.${index + 1} - ${chapter.name}`}
                                                                </h5>

                                                                {chapter.course_lessons && chapter.course_lessons.length !== 0 && (<span className='ms-auto'>{chapter.course_lessons.length}-Lessons</span>)}
                                                            </div>

                                                            {chapter.course_lessons && chapter.course_lessons.length !== 0 && chapter.id === chapterId && (
                                                                <div className='bg-white pt-2 pb-2'>
                                                                    {chapter.course_lessons.map((lesson, lessonIndex) => (
                                                                        <Row key={lessonIndex}>
                                                                            <Col className='mt-2 ms-4 me-4 course-chapter-lesson'>
                                                                                <div className='ms-3'>
                                                                                    <div className='d-flex'>
                                                                                        {matchWithCourseLesson(lesson.id) ?
                                                                                            <span>
                                                                                                <i className="fa fa-check me-2 status-circle" aria-hidden="true"></i>
                                                                                            </span>
                                                                                            : <span>
                                                                                                <i className="fa fa-check me-2 empty-status-circle" aria-hidden="true"></i>
                                                                                            </span>}
                                                                                        <i className="fa fa-film mt-1 me-2" aria-hidden="true"></i>
                                                                                        <div className='mb-0'>
                                                                                            {userDetail ?
                                                                                                <Link to={`/course/${id}/lesson/${lesson.id}`} className='text-decoration-none text-dark'>
                                                                                                    {lesson.name}
                                                                                                </Link>
                                                                                                :
                                                                                                <Link onClick={() => handleShow(`/course/${id}/lesson/${lesson.id}`)} className='text-decoration-none text-dark'>
                                                                                                    {lesson.name}
                                                                                                </Link>
                                                                                            }
                                                                                        </div>

                                                                                        {lesson.vimeo_video_id && (
                                                                                            <div className='ms-auto'>
                                                                                                <VimeoPlayerDuration videoId={lesson.vimeo_video_id} />
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    ))}
                                                                </div>
                                                            )}

                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                        {Data.course_requirement !== undefined && Data.course_requirement.length !== 0 && (
                                            <div className='course-requirements mt-5 mb-5'>
                                                <h4 className='mb-4 fw-bold'>{`Course Requirement`}</h4>
                                                <ul type="disc">
                                                    {Data.course_requirement.map((obj, index) =>
                                                        <li className='mb-2' key={`course_requirement_${index}`}>{obj.name}</li>
                                                    )}

                                                </ul>
                                            </div>
                                        )}
                                        {Data.description !== undefined && (
                                            <div className='course-description mt-5 mb-5'>
                                                <h4 className='mb-4 fw-bold'>{`Course Description`}</h4>

                                                <>
                                                    <div className={`desc mb-2 ${readMore === false ? '' : 'expanded'}`}>
                                                        <p>{parse(String(Data.description))}</p>
                                                    </div>
                                                    {readMore === false ?
                                                        <span style={{ cursor: "pointer" }} onClick={() => setReadMore(true)}>
                                                            Read More <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                        </span>
                                                        :
                                                        <span style={{ cursor: "pointer" }} onClick={() => setReadMore(false)}>
                                                            Read Less <i className="fa fa-angle-up" aria-hidden="true"></i>
                                                        </span>
                                                    }
                                                </>

                                            </div>
                                        )}
                                    </Col>
                                    <Col lg={4} sm={12} xs={12}>
                                        {latestCourses !== undefined && latestCourses.length !== 0 && (
                                            <div className='latest-courses border border-dark p-3 pb-0 mt-5 mb-4'>
                                                <h4 className='mb-4 fw-bold'>{`Recently Added Courses`}</h4>
                                                <Row style={{ backgroundColor: "rgb(247, 249, 250)", marginRight: "-15px", marginLeft: "-15px" }} className='py-2'>
                                                    {latestCourses.map((obj, index) => (
                                                        <div className='mt-2 mb-2 d-flex' key={`latest_course_${index}`}>
                                                            <Col lg={2}>
                                                                <img className='rounded-2' src={`${process.env.REACT_APP_API_BASE_URL}courselesson/download/${obj.image}`} height="60" width="70" />
                                                            </Col>
                                                            <Col lg={10} className='d-flex align-items-center'>
                                                                <span className='ms-3'><a className='text-decoration-none text-dark fw-bold' href={`${frontBaseUrl}/course/${obj.id}`}>{obj.name}</a></span>
                                                            </Col>
                                                        </div>
                                                    ))}
                                                </Row>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                    </div>
                    <Footer />
                </>
            )}
        </>
    );
}

export default CourseDetail;
