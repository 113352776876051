import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const baseLaravelURL = process.env.REACT_APP_LARAVEL_BASE_URL;

const apiService = axios.create({
  baseURL,
});

const apiLaravelService = axios.create({
  baseLaravelURL,
});

export const getCourseListData = async (query) => {
  try {
    const response = await apiService.get(`/course?active=1&${query}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching list data:', error);
    throw error;
  }
};

export const getCourseById = async (Id) => {
  try {
    const response = await apiService.get(`/course/${Id}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching list data:', error);
    throw error;
  }
}

export const getCourseLessonById = async (Id) => {
  try {
    const response = await apiService.get(`/courselesson/${Id}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching list data:', error);
    throw error;
  }
};

export const getCourseLessonByUserId = async (Id, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  try {
    const response = await apiService.get(`/courselessonuserid/${Id}`,{headers});
    return response.data.data;
  } catch (error) {
    console.error('Error fetching list data:', error);
    throw error;
  }
}

export const createCourseLessonPositionOfUserId = async (query, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  try {
    const response = await apiService.post(`/courselessonuserid?${query}`, null, { headers });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching list data:', error);
    throw error;
  }
}

export const getPageDetails = async (query) => {
  try {
    const response = await apiService.get(`/page_data/${query}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching list data:', error);
    throw error;
  }
}

export const getFirstCourseDetails = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_LARAVEL_BASE_URL}/first-course`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching list data:', error);
    throw error;
  }
}