import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const login = async (email, password) => {
    try {
      const response = await axios.post(`${BASE_URL}user/login`, { email, password });
      const token = response.data.data.token; // Check the actual structure of your API response
      return token;
    } catch (error) {
      throw new Error(error.response.data.message || 'Login failed');
    }
  };

export const verifyToken = async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.post(`${BASE_URL}user/verify-token`, {}, { headers });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Token verification failed');
    }
  };

  export const logout = () => {
    //  const {logout: authLogout} = useAuth();
    //  authLogout();
    localStorage.removeItem('token');
    localStorage.removeItem('userdata');
  };
  
