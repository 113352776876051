import React, { useState, useEffect } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const ToastComponent = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.show === true) {
      setShow(props.show);
    }
  }, [props.show]);

  function handleChange() {
    props.onChange(false);
    setShow(false);
  }

  return (
    <div>
      <ToastContainer className="p-3 position-fixed" position="top-end">
        <Toast  style={{backgroundColor: `rgb(34, 84, 211)`}} onClose={handleChange} show={show} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{props.title}</strong>
          </Toast.Header>
          <Toast.Body className='text-start text-white'>{props.msg}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export { ToastComponent };
