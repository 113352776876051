import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import Academy from './Academy';
import Home from './Home';
import CreateAccount from './CreateAccount';
import Login from './Login';
import Contact from './Contact';
import Course from './Course';
import CourseDetail from './CourseDetail';
import { useState } from 'react';
import CourseLesson from './CourseLesson';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const PrivateRoute = ({ element }) => {
  const { userDetail } = useAuth();

  return userDetail ? (
    element
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

const App = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { userDetail } = useAuth();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email format').required('Email is required'),
      message: Yup.string().required('Message is required'),
    }),
    onSubmit: async (values, {resetForm}) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}common-send-mail`, values);
        if (response.status === 200) {
          resetForm();
          alert('Email sent successfully');
        } else {
          alert('Failed to send email');
        }
      } catch (error) {
        alert('An error occurred');
      }
    },
  });

  return (
    <>
      {show ?
        <div className='common-modal'>
          <div className='modal-common-header' style={{ position: "relative", zIndex: "1" }}>
            <h2>Ready For Uni</h2>
          </div>

          <div className='message-box' style={{ position: "relative", zIndex: "2" }}>
            <p>Ciao! Scrivici per qualsiasi domanda, ti risponderemo prima possibile!</p>
          </div>

          <div className='message-icon'>
            <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24" data-ux="Icon" className="x-el x-el-svg c2-1 c2-2 c2-41 c2-r c2-s c2-42 c2-43 c2-1h c2-44 c2-45 c2-46 c2-3 c2-4 c2-5 c2-6 c2-7 c2-8"><path d="M0.342304 14.5C7.35025 6.3293 3.35025 0.829295 0 0.0.0 0.0 5.4 2.1 32.3502 0.329295C32.3503 3.8293 -3.13481 20.7261 0.342304 14.5Z"></path></svg>
          </div>

          <form className='modal-common-form' onSubmit={formik.handleSubmit}>
            <center>
              <div className="form-floating mb-2">
                <input type="text" autoComplete='off' className={`form-control ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`} id="floatingInput" placeholder="Nome" {...formik.getFieldProps('name')} />
                <label htmlFor="floatingInput">Nome*</label>
                {formik.touched.name && formik.errors.name && (
                  <div className="error text-danger text-start">{formik.errors.name}</div>
                )}
              </div>

              <div className="form-floating mb-2">
                <input type="email" autoComplete='off' className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`} id="floatingInput" placeholder="name@example.com" {...formik.getFieldProps('email')} />
                <label htmlFor="floatingInput">Email*</label>
                {formik.touched.email && formik.errors.email && (
                  <div className="error text-danger text-start">{formik.errors.email}</div>
                )}
              </div>

              <div className="form-group">
                <textarea autoComplete='off' className={`form-control ${formik.touched.message && formik.errors.message ? 'is-invalid' : ''}`} id="exampleFormControlTextarea1" rows="6" placeholder='Come possiamo esserti di aiuto?*' {...formik.getFieldProps('message')}></textarea>
                {formik.touched.message && formik.errors.message && (
                  <div className="error text-danger text-start">{formik.errors.message}</div>
                )}
              </div>

              <button className='mt-3' disabled={formik.isSubmitting}>
                <span>{formik.isSubmitting ? 'Please Wait...' : 'INVIA'}</span>
              </button>
              <div className='mt-4'>
                <p>Questo sito è protetto da reCAPTCHA e sono applicate le <a href="https://policies.google.com/privacy">Politica sulla privacy</a> e i <a href="https://policies.google.com/terms">Termini di utilizzo</a> di Google.</p>
              </div>
            </center>
          </form>
        </div> : ""}


      <Router basename={process.env.REACT_APP_BASE_URL} style={{ position: 'relative' }}>
        {/* {userDetail !== null ? <Navigate to="/" replace={true} /> : <Navigate to="/login" replace={true} />} */}
        <div>
          <Routes>
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/login" element={<Login />} />

            <Route path="/" element={<Home />} />
            <Route path="/academy" element={<Academy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/course" element={<Course />} />
            <Route path="/course/:id" element={<CourseDetail />} />
            {/* <Route path="/course/:id/lesson/:id" element={<CourseLesson />} /> */}
            {/* {userDetail ? <Route path="/course/:id/lesson/:id" element={<CourseLesson />} /> : <></>} */}
            
            <Route
              path="/course/:id/lesson/:id"
              element={
                <PrivateRoute element={<CourseLesson />} />
              }
            />
          </Routes>
        </div>
        {show ?
          <button className='message-bottom-close-button' onClick={handleClose}>
            <svg viewBox="0 0 24 24" fill="currentColor" width="34" height="34" data-ux="Icon" className="x-el x-el-svg c2-1 c2-2 c2-36 c2-r c2-s c2-3 c2-4 c2-5 c2-6 c2-7 c2-8"><path fillRule="evenodd" d="M19.245 4.313a1.065 1.065 0 0 0-1.508 0L11.78 10.27 5.82 4.313A1.065 1.065 0 1 0 4.312 5.82l5.958 5.958-5.958 5.959a1.067 1.067 0 0 0 1.508 1.508l5.959-5.958 5.958 5.958a1.065 1.065 0 1 0 1.508-1.508l-5.958-5.959 5.958-5.958a1.065 1.065 0 0 0 0-1.508"></path></svg>
          </button>
          :
          <button className='message-bottom-button' onClick={handleShow}>
            <svg viewBox="0 0 24 24" fill="currentColor" width="44" height="44" data-ux="Icon" className="x-el x-el-svg c2-1 c2-2 c2-q c2-r c2-s c2-3 c2-4 c2-5 c2-6 c2-7 c2-8"><g fill="currentColor"><rect x="4" y="6" width="16" height="10.222" rx="1.129"></rect><path d="M8.977 18.578l.2-2.722a.564.564 0 01.564-.523h3.61c.548 0 .774.705.327 1.024l-3.81 2.721a.564.564 0 01-.89-.5z"></path></g></svg>
          </button>
        }

        {/* <Footer /> */}
      </Router>
    </>
  );
};

export default App;

