
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Navbar, Nav, Container, NavDropdown, Modal, Button } from 'react-bootstrap';
import { getPageDetails } from '../../api/CourseApi';
import { ToastComponent } from '../common/ToastComponent';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { logout } from '../../api/AuthApi';

const CommonNavbar = ({ courseLesson }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const { logout: authLogout } = useAuth();

    const [isSticky, setIsSticky] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        const roundedOffset = Math.floor(offset / 10) * 70; // Round down to the nearest 10
        setIsSticky(roundedOffset > 30);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [backgroundColor, setBackgroundColor] = useState(currentPath == "/" ? "" : "rgb(34, 84, 211)");
    const [imgHeight, setImgHeight] = useState(100);

    useEffect(() => {
        if (currentPath === "/") {
            setBackgroundColor(isSticky ? "rgb(34, 84, 211, 0.9)" : "");
            setImgHeight(isSticky ? 60 : 100);
        } else if (typeof courseLesson !== 'undefined') {
            setBackgroundColor("rgb(34, 84, 211, 0.9)");
            setImgHeight(60);
        } else {
            setBackgroundColor(isSticky ? "rgb(34, 84, 211, 0.9)" : "rgb(34, 84, 211)");
            setImgHeight(isSticky ? 60 : 100);
        }
    }, [isSticky, currentPath]);

    const CustomDivider = () => (
        <hr style={{ borderTop: '2px solid white', marginTop: "7px", marginBottom: "7px" }} />
    );

    const [navSideBar, setNavSideBar] = useState(false);


    // const { logoutdevice : authLogout } = useAuth();

    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [toastText, setToastText] = useState({
        status: "danger",
        text: "error"
    });


    const handleChange = (value) => {
        setShow(value);
    };

    const [logOutConfirmation, setLogOutConfirmation] = useState(false);

    const handleClose = () => setLogOutConfirmation(false);
    const handleShow = () => setLogOutConfirmation(true);

    const navigate = useNavigate();
    const logoutapp = async () => {
        // logout();
        authLogout();
        setShow(true);
        setToastText({
            status: "success",
            text: "User Logout successfully"
        })
        setMessage("User Logout successfully");
        setTimeout(() => {
            navigate(`/`, { replace: true });
        }, 2000);
        handleClose();
    }


    const frontBaseUrl = process.env.REACT_APP_BASE_URL;

    const [Data, setData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPageDetails('navbar');
                setData(data);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    const imageURL = process.env.REACT_APP_CMS_IMAGE_URL;


    const NavbarData = Data && Data.page_section && Data.page_section.desktopnav;
    const NavbarLink = Data && Data.page_section && Data.page_section.desktopnav.navbarlink.attributes;
    const SubNavbarLink = Data && Data.page_section && Data.page_section.desktopnav.subnavbarlink.attributes;
    const SideNavbarData = Data && Data.page_section && Data.page_section.sidenav;
    const TopSideNavbarData = Data && Data.page_section && Data.page_section.sidenav.topnavbarlink.attributes;
    const BottomSideNavbarData = Data && Data.page_section && Data.page_section.sidenav.bottomnavbarlink.attributes;

    const { userDetail } = useAuth();


    return (
        <>
            <ToastComponent bg={toastText.status} title={toastText.text} msg={message} show={show} onChange={handleChange} />

            <Modal show={logOutConfirmation} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to log out?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={logoutapp}>
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>
            {SideNavbarData && (
                <Modal className={`${navSideBar ? "mobile-sidebar" : "mobile-sidebar-out"} `} animation="slide" show={navSideBar} onHide={() => setNavSideBar(false)} fullscreen={true}>
                    <Container>
                        <div className='header d-flex justify-content-end mt-4' onClick={() => setNavSideBar(false)}>
                            <svg viewBox="0 0 24 24" fill="currentColor" width="28px" height="28px" data-ux="CloseIcon" data-edit-interactive="true" data-close="true" className="x-el x-el-svg c1-1 c1-2 c1-1i c1-32 c1-3u c1-4u c1-4v c1-4w c1-4x c1-z c1-7m c1-7n c1-60 c1-7o c1-7p c1-7q c1-b c1-7r c1-7s c1-7t c1-7u c1-7v"><path fill-rule="evenodd" d="M19.245 4.313a1.065 1.065 0 0 0-1.508 0L11.78 10.27 5.82 4.313A1.065 1.065 0 1 0 4.312 5.82l5.958 5.958-5.958 5.959a1.067 1.067 0 0 0 1.508 1.508l5.959-5.958 5.958 5.958a1.065 1.065 0 1 0 1.508-1.508l-5.958-5.959 5.958-5.958a1.065 1.065 0 0 0 0-1.508"></path></svg>
                        </div>
                        <Modal.Body>
                            {TopSideNavbarData && (
                                TopSideNavbarData.map((obj, index) => (
                                    <Nav.Link key={`top-sidenavbar-${index}`} href={`${frontBaseUrl}${obj.link.value}`} className={`sidebar-nav-link ${currentPath == `${obj.link.value}` ? "active" : ""}`}><span>{obj.name.value}</span></Nav.Link>
                                ))
                            )}

                            <div className="mt-4">
                                <form className="form-inline">
                                    <input className="form-control transparent-search-bar" type="search" placeholder="Cerca prodotti" aria-label="Search" />
                                </form>
                            </div>

                            {/* {userDetail ? (
                                <Nav.Link onClick={handleShow} className={`sidebar-nav-link`}><span>Logout</span></Nav.Link>
                            ) : (
                                BottomSideNavbarData && (
                                    BottomSideNavbarData.map((obj, index) => (
                                        <Nav.Link key={`bottom-sidenavbar-${index}`} href={`${frontBaseUrl}${obj.link.value}`} className={`sidebar-nav-link ${currentPath == `${obj.link.value}` ? "active" : ""}`}><span>{obj.name.value}</span></Nav.Link>
                                    ))
                                )
                            )} */}

                            {/* {BottomSideNavbarData && (
                                BottomSideNavbarData.map((obj, index) => (
                                    <Nav.Link key={`bottom-sidenavbar-${index}`} href={`${frontBaseUrl}${obj.link.value}`} className={`sidebar-nav-link ${currentPath == `${obj.link.value}` ? "active" : ""}`}><span>{obj.name.value}</span></Nav.Link>
                                ))
                            )} */}
                        </Modal.Body>
                    </Container>
                </Modal>
            )}

            {NavbarData && (
                <>
                    <div className='thick-top-nav'>
                        <a href={`${process.env.REACT_APP_LARAVEL_COMMON_LINK}/student_quiz`} target="_blank" className='text-decoration-none'>
                            <h4>{NavbarData.uppertext.value}</h4>
                        </a>
                    </div>
                    <Navbar expanded={false} style={{ backgroundColor: backgroundColor, position: currentPath == `/` ? "fixed" : "sticky", top: currentPath == `/` ? isSticky ? "0" : "50px" : "0", width: "100%", zIndex: "100" }} variant="dark" expand="lg">
                        <Container fluid>
                            <Navbar.Brand>
                                <Link to="/">
                                    <img className='logo-img' src={`${imageURL}/${NavbarData.logo.value}`} alt="logo" height={imgHeight} style={{ transition: "height 0.5s ease" }} />
                                </Link>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setNavSideBar(true)} />
                            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                                <Nav className="ml-auto">

                                    {NavbarLink && (
                                        NavbarLink.map((obj, index) => (
                                            <Link to={`${obj.link.value}`} className={`nav-link ${currentPath === `${obj.link.value}` ? "nav-active" : ""}`} key={`navbar-${index}`}>
                                                {obj.name.value}
                                            </Link>
                                        ))
                                    )}

                                    {/* <div className='text-white' style={{ marginTop: "-1px", margin: "0px 5px 0px 5px" }}>|</div>

                                    <NavDropdown title={<svg viewBox="0 0 24 24" fill="currentColor" width="25px" height="25px" data-ux="UtilitiesMenuIcon" data-typography="NavAlpha" className="x-el x-el-svg c2-t c2-u c2-16 c2-r c2-1s c2-1t c2-s c2-1c c2-1d c2-1e c2-1f c2-1g c2-19 c2-j c2-3 c2-y c2-z c2-10 c2-11 c2-12 c2-13 c2-14 c2-15"><path fillRule="evenodd" d="M16.056 8.255a4.254 4.254 0 1 1-8.507 0 4.254 4.254 0 0 1 8.507 0zm3.052 11.71H4.496a.503.503 0 0 1-.46-.693 8.326 8.326 0 0 1 7.766-5.328 8.326 8.326 0 0 1 7.766 5.328.503.503 0 0 1-.46.694z"></path></svg>}
                                        id="basic-nav-dropdown"
                                    >
                                        {userDetail ? (
                                            <NavDropdown.Item onClick={handleShow}>
                                                logout
                                            </NavDropdown.Item>
                                        ) : (
                                            SubNavbarLink && (
                                                SubNavbarLink.map((obj, index) => (
                                                    <NavDropdown.Item key={`sub-navbar-${index}`} href={`${frontBaseUrl}${obj.link.value}`}>
                                                        {obj.name.value}
                                                    </NavDropdown.Item>
                                                ))
                                            )
                                        )}
                                    </NavDropdown> */}
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </>
            )}


        </>
    )
}

export default CommonNavbar;