// About.js
import React from 'react';
import CommonNavbar from './component/common/Navbar';
import { Container } from 'react-bootstrap';
import Footer from './component/common/Footer';

const CreateAccount = () => {
  return (
    <>
      <CommonNavbar />
      <div className='create-account mt-5 mb-5'>
        <Container>
          <center>
            <h2>
              Crea account
            </h2>
            <form>
              <div className="form-group">
                <input type="text" className="form-control mt-4" placeholder="Nome" />
                <input type="text" className="form-control mt-4" placeholder="Cognome" />
                <input type="email" className="form-control mt-4" placeholder="Email" />
                <input type="text" className="form-control mt-4" placeholder="Telofono (facoltativo)" />
                {/* <input type="password" className="form-control mt-3" id="exampleInputPassword1" placeholder="Password" /> */}
              </div>
              <div className="form-group form-check mt-4 d-flex justify-content-center">
                <input type="checkbox" className="form-check-input py-0" id="exampleCheck1" />
                <label className="form-check-label ms-2" htmlFor="exampleCheck1">Registrami per ricevere via email vantaggi esclusivi per i membri.</label>
              </div>
              <button className='mt-3'>
                <a href="">CREA ACCOUNT</a>
              </button>
              <div className='mt-4'>
               <p>Hai già un account? <a href="/login">Entra</a> </p>
              </div>
              <div className='mt-4'>
                <p>Questo sito è protetto da reCAPTCHA e sono applicate le <a href="https://policies.google.com/privacy">Politica sulla privacy</a> e i <a href="https://policies.google.com/terms">Termini di utilizzo</a> di Google.</p>
              </div>
              
            </form>
          </center>
        </Container>

      </div>
      <Footer />
    </>
  );
};

export default CreateAccount;
