import { Navbar, Nav, Container, Col, Row, Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import CommonNavbar from './component/common/Navbar';
import { getPageDetails, getFirstCourseDetails } from './api/CourseApi';
import Footer from './component/common/Footer';
import parse from 'html-react-parser';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';


const Academy = () => {
    const [faq1, setFaq1] = useState(false);
    const [faq2, setFaq2] = useState(false);
    const [faq3, setFaq3] = useState(false);

    const [courseData, setCourseData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getFirstCourseDetails();
                setCourseData(data);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    console.log("courseData", courseData);

    const frontBaseUrl = process.env.REACT_APP_BASE_URL;

    const [Data, setData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPageDetails('academy');
                setData(data);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    const imageURL = process.env.REACT_APP_CMS_IMAGE_URL;

    const Banner = Data && Data.page_section && Data.page_section.banner;
    const Academy = Data && Data.page_section && Data.page_section.academycard;
    const AcademyCard = Data && Data.page_section && Data.page_section.academycard.card.attributes;
    const Email = Data && Data.page_section && Data.page_section.email;
    const FAQ = Data && Data.page_section && Data.page_section.faqs;
    const FAQList = Data && Data.page_section && Data.page_section.faqs.faqs.attributes;

    const AcademyBanner = {
        backgroundPosition: '49.5652% 42.1739%',
        backgroundSize: 'auto, cover',
        backgroundBlendMode: 'normal',
        backgroundRepeat: 'no-repeat',
        backgroundImage: Banner ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.37) 0%, rgba(0, 0, 0, 0.37) 100%), url("${imageURL}/${Banner.image.value}")` : 'none',
    };

    const [faqCurrentIndex, setFaqCurrentIndex] = useState();

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Email id is required'),
    });

    const initialValues = {
        email: '',
    };

    const onSubmit = async (values, { resetForm }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}common-send-mail`, values);
            if (response.status === 200) {
                resetForm();
                alert('Email sent successfully');
            } else {
                alert('Failed to send email');
            }
        } catch (error) {
            alert('An error occurred');
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return (
        <>
            <CommonNavbar />
            <div className="academy">
                {Banner && (
                    <div className="banner" style={AcademyBanner}>
                        <Container>
                            <div className='title'>
                                <Row>
                                    <Col lg={10}>
                                        <h2>
                                            {Banner.title.value}
                                        </h2>
                                    </Col>
                                    <Col lg={2} className='d-flex align-items-center justify-content-center'>
                                        <button>
                                            <a href={`${process.env.REACT_APP_LARAVEL_COMMON_LINK}/academy/${courseData?.id}`} target="_blank">{Banner.button_name.value}</a>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                )}

                {Academy && (
                    <div className='about'>
                        <h2>
                            {Academy.title.value}
                        </h2>
                        <Container>
                            <div className='a-card'>
                                <center>
                                    <Row>
                                        {AcademyCard && (
                                            AcademyCard.map((obj, index) =>
                                                <Col lg={4} className='mt-4' key={`academy_card_${index}`}>
                                                    <img src={`${imageURL}/${obj.image.value}`} alt={`academy_card_${index}`} />
                                                    <h4 className='mt-3'>{parse(String(obj.title.value))}</h4>
                                                    <p>{parse(String(obj.description.value))}</p>
                                                </Col>
                                            )
                                        )}

                                        {/* <Col lg={4} className='mt-4'>
                                            <img src={`${frontBaseUrl}/complete-online-course.webp`} alt="complete-online-course" />
                                            <h4 className='mt-3'>CORSO ONLINE COMPLETO</h4>
                                            <p>Preparati con le <strong> videolezioni complete </strong> della professoressa Rossi</p>

                                        </Col>
                                        <Col lg={4} className='mt-4'>
                                            <img src={`${frontBaseUrl}/UPDATED-POLIRATION.webp`} alt="UPDATED-POLIRATION" />
                                            <h4 className='mt-3'>SIMULAZIONI AGGIORNATE</h4>
                                            <p>Con il <strong>simulatore online</strong> sai esattamente cosa aspettarti dal test ufficiale!</p>
                                        </Col>
                                        <Col lg={4} className='mt-4'>
                                            <img src={`${frontBaseUrl}/INTERNAL-GRADUATORIES.webp`} alt="INTERNAL-GRADUATORIES" />
                                            <h4 className='mt-3'>GRADUATORIE INTERNE</h4>
                                            <p><strong>Confrontati con altri studenti</strong> per capire dove migliorare</p>
                                        </Col>
                                        <Col lg={4} className='mt-4'>
                                            <img src={`${frontBaseUrl}/TIME-PLAN.webp`} alt="TIME-PLAN" />
                                            <h4 className='mt-3'>TIME PLAN</h4>
                                            <p>Con il Time Plan gestisci le <strong>sessioni di studio</strong> e i tuoi <strong>obiettivi</strong> senza perdere la <strong>motivazione</strong></p>
                                        </Col>
                                        <Col lg={4} className='mt-4'>
                                            <img src={`${frontBaseUrl}/STATISTICAL.webp`} alt="STATISTICAL" />
                                            <h4 className='mt-3'>STATISTICHE</h4>
                                            <p>Con le statistiche puoi tenere traccia dei tuoi <strong>progressi</strong>, dei tuoi <strong>punti forti</strong> e deboli</p>
                                        </Col>
                                        <Col lg={4} className='mt-4'>
                                            <img src={`${frontBaseUrl}/SUPPORT-WHATSAPP.webp`} alt="SUPPORT-WHATSAPP" />
                                            <h4 className='mt-3'>SUPPORTO WHATSAPP 24/7</h4>
                                            <p><strong>Non sarai mai solo!</strong> Prima di tutto, R4U è una community a cui rivolgersi per qualsiasi domanda o consiglio</p>
                                        </Col> */}
                                    </Row>
                                </center>
                            </div>
                        </Container>
                    </div>
                )}

                {FAQ && (
                    <div className='course-structure'>
                        <div className='mt-5 mb-5'>
                            <h2>
                                {FAQ.title.value}
                            </h2>
                            {/* <Table>
                                <tr className="table-row mb-5">
                                    <td>
                                        {faq1 ? <h3 onClick={() => setFaq1(false)} style={{ color: "rgb(34, 84, 211)" }}>Prima domanda</h3> : <h3 onClick={() => setFaq1(true)}>Prima domanda</h3>}
                                        {faq1 ?
                                            <p
                                                dangerouslySetInnerHTML={
                                                    {
                                                        __html:
                                                            `Aggiungi una risposta a questa voce.`
                                                    }
                                                }
                                            >
                                            </p>
                                            :
                                            <></>
                                        }
                                    </td>
                                </tr>
                                <tr className="table-row">
                                    <td>
                                        {faq2 ? <h3 onClick={() => setFaq2(false)} style={{ color: "rgb(34, 84, 211)" }}>Seconda domanda</h3> : <h3 onClick={() => setFaq2(true)}>Seconda domanda</h3>}
                                        {faq2 ?
                                            <p
                                                dangerouslySetInnerHTML={
                                                    {
                                                        __html:
                                                            `Aggiungi una risposta a questa voce.`
                                                    }
                                                }
                                            >
                                            </p> : <></>}

                                    </td>
                                </tr>
                                <tr className="table-row">
                                    <td>
                                        {faq3 ? <h3 onClick={() => setFaq3(false)} style={{ color: "rgb(34, 84, 211)" }}>Terza domanda</h3> : <h3 onClick={() => setFaq3(true)}>Terza domanda</h3>}
                                        {faq3 ?
                                            <p
                                                dangerouslySetInnerHTML={
                                                    {
                                                        __html:
                                                            `Aggiungi una risposta a questa voce.`
                                                    }
                                                }
                                            >
                                            </p>
                                            : <></>}

                                    </td>
                                </tr>
                            </Table> */}
                            {FAQList && (
                                <Table>
                                    {FAQList.map((obj, index) =>
                                        <tr className="table-row" key={`Faq_List_${index}`}>
                                            <td>
                                                {faqCurrentIndex == index ? <h3 style={{ color: "rgb(34, 84, 211)" }} onClick={() => setFaqCurrentIndex(index)}>{obj.question.value}</h3> : <h3 onClick={() => setFaqCurrentIndex(index)}>{obj.question.value}</h3>}
                                                {faqCurrentIndex == index ?
                                                    <p
                                                        dangerouslySetInnerHTML={
                                                            {
                                                                __html:
                                                                    `${obj.answer.value}`
                                                            }
                                                        }
                                                    >
                                                    </p> :
                                                    <></>
                                                }

                                            </td>
                                        </tr>
                                    )}
                                </Table>
                            )}

                        </div>

                    </div>
                )}


                {/* <div className='soon'>
                    <div style={{ marginBottom: "100px" }}>
                        <Container>
                            <center>
                                <div className='post'>
                                    <span>
                                        Nuovi prodotti presto disponibili!
                                    </span>
                                </div>
                            </center>
                        </Container>
                    </div>
                </div> */}

                {courseData && (
                    <div className='course-detail'>
                        <div className='header' style={{ backgroundColor: "#2d2f31", padding: "3.2rem 0 3.2rem 0" }}>
                            <Container>
                                <Row>
                                    <Col lg={8} sm={12} xs={12} className='my-auto'>
                                        <h2 className='mb-4 text-white'>{courseData.name}</h2>
                                        {courseData.short_description !== undefined && (
                                            <p className='text-white'>
                                                {courseData.short_description}
                                            </p>
                                        )}
                                        <a href={`${process.env.REACT_APP_LARAVEL_COMMON_LINK}/academy/${courseData.id}`} target="_blank">
                                            <button className='course-button'>
                                               Go To Course
                                            </button>
                                        </a>

                                    </Col>
                                    <Col lg={4} sm={12} xs={12}>
                                        <div className='course-lesson-image' >
                                            <img src={`${process.env.REACT_APP_LARAVEL_IMAGE_URL}/${courseData.image}`} alt='course-data' className='rounded-0' />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                )}


                {Email && (
                    <div className='register mt-5 mb-5'>
                        <Container>
                            <form onSubmit={formik.handleSubmit}>
                                <Row className='mt-4 mb-4' >
                                    <Col lg={2} className='d-flex align-items-center justify-content-center'>
                                        <h3>
                                            {Email.title.value}
                                        </h3>
                                    </Col>
                                    <Col lg={8}>
                                        <div className="form-floating">
                                            <input
                                                type="email"
                                                className={`form-control custom-input`}
                                                id="floatingInput"
                                                placeholder="name@example.com"
                                                {...formik.getFieldProps('email')}
                                                autoComplete="off"
                                            />
                                            <label htmlFor="floatingInput">Email</label>
                                            {formik.touched.email && formik.errors.email && (
                                                <div className="error text-danger fw-bold mt-1 text-start">{formik.errors.email}</div>
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg={2} className='d-flex align-items-center justify-content-center'>
                                        <button type="submit" disabled={formik.isSubmitting}>
                                            {formik.isSubmitting ? <a>Wait...</a> : <a>Registrati</a>}
                                        </button>
                                    </Col>
                                </Row>
                            </form>

                            <p className='mt-4 mb-4'>
                                {Email.description.value}
                            </p>
                        </Container>
                    </div>
                )}


            </div>
            <Footer />
        </>
    )
}

export default Academy;