import React, { useEffect, useState } from 'react';
import { getCourseListData, getPageDetails } from './api/CourseApi';
import CommonNavbar from './component/common/Navbar';
import { Container, Col, Row } from 'react-bootstrap';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import Footer from './component/common/Footer';

const Course = () => {

    const descriptionStyle = {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 5,
        height: '120px',
        wordBreak: 'break-all',
    };

    const titleStyle = {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 2,
        height: '50px',
        wordBreak: 'break-all',
    }

    const [listData, setListData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCourseListData();
                setListData(data);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    const [Data, setData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPageDetails('courses');
                setData(data);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);


    const imageURL = process.env.REACT_APP_CMS_IMAGE_URL;

    const Banner = Data && Data.page_section && Data.page_section.banner;

    const CoursesBanner = {
        backgroundPosition: '49.5652% 42.1739%',
        backgroundSize: 'auto, cover',
        backgroundBlendMode: 'normal',
        backgroundRepeat: 'no-repeat',
        backgroundImage: Banner ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.37) 0%, rgba(0, 0, 0, 0.37) 100%), url("${imageURL}/${Banner.image.value}")` : 'none',
    };

    return (
        <>
            <CommonNavbar />
            <div className='course-listing'>
                {Banner && (
                    <div className="banner" style={CoursesBanner}>
                        <Container>
                            <div className='title'>
                                <Row>
                                    <Col>
                                        <h2>
                                          {Banner.title.value}
                                        </h2>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                )}

                <Container>
                    <div className='mt-5 mb-5'>
                        <Row>
                            {listData.map((obj, index) => {
                                if (obj.description != "" && obj.description !== null) {
                                    var firstParagraphElement = obj.description.split("</p>")[0];
                                    var paragraphContent = firstParagraphElement.replace("<p>", "")
                                }

                                return (
                                    <Col md={3} lg={3} sm={1} className='mt-4' key={index}>
                                        <div className='course-card'>
                                            <div className='course-image'>
                                                <img src={`${process.env.REACT_APP_API_BASE_URL}courselesson/download/${obj.image}`} alt="course-image" />
                                            </div>
                                            <div className='upper-section'>
                                                <Link to={`/course/${obj.id}`} className='text-decoration-none'>
                                                    <h3 style={titleStyle}>{obj.name}</h3>
                                                </Link>
                                                <p className='mb-2' style={descriptionStyle}>{parse(paragraphContent)}</p>
                                            </div>
                                            <button className='mt-2'>
                                                <span>Buy Now</span>
                                            </button>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default Course;