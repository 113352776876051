// AuthContext.js
import { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [userDetail, setUserDetail] = useState(JSON.parse(localStorage.getItem('userdata')));

  const login = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
  };

  const verifyToken = (userData) => {
    setUserDetail(userData);
    localStorage.setItem('userdata', JSON.stringify(userData));
  }

  const logout = () => {
    setToken(null);
    setUserDetail(null);
    localStorage.removeItem('token');
    localStorage.removeItem('userdata');
  };

  return (
    <AuthContext.Provider value={{ token, userDetail, login, verifyToken , logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
