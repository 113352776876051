import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCourseLessonById } from './api/CourseApi';
import CommonNavbar from './component/common/Navbar';
import { Container, Row, Col } from 'react-bootstrap';
import VimeoPlayer from './component/structural/VimeoPlayer';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import Footer from './component/common/Footer';



const CourseLesson = () => {
    const { id } = useParams();

    const [Data, setData] = useState([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCourseLessonById(id);
                setData(data);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, [id]);


    const Download = async () => {
        if (Data && Data.document && Data.name) {
            try {
                const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}courselesson/download/${Data.document}`;
                const response = await fetch(downloadUrl);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${Data.name.replace(/[^\w\s]/gi, '').replace(/\s+/g, '')}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        }
    };

    return (
        <>
            {Data && Data.name && Data.description ? (
                <div style={{ minHeight: '100vh' }}>
                    <Modal show={show} onHide={handleClose} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>{Data.name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}>{parse(String(Data.description))}</Modal.Body>
                    </Modal>
                    <CommonNavbar courseLesson={true} />
                    <Container className='course-lesson-detail pe-4' fluid>
                        <Row>
                            <Col lg={6} sm={12} xs={12} md={12} className='mt-5 mb-5'>
                                <VimeoPlayer videoId={Data.vimeo_video_id} courseLessonId={id} />
                            </Col>
                            <Col lg={6} sm={12} xs={12} md={12} className='mt-5 mb-5 border border-dark position-relative px-4'>
                                <h4 className='mt-3'>{Data.name}</h4>
                                <div className='course-lesson-description d-flex flex-column'>
                                    <p className={`desc mb-1`} >{parse(String(Data.description))}</p>
                                    <span className='mt-3' style={{ color: "rgba(34, 84, 211)" }}>
                                        <span className='fw-bold' style={{ cursor: "pointer", marginTop: "auto" }} onClick={handleShow}>
                                            Read More <i className="fa fa-angle-down fw-bold" aria-hidden="true"></i>
                                        </span>
                                        <span className='ms-3 fw-bold' style={{ cursor: "pointer", marginTop: "auto" }} onClick={Download}>
                                            Download Pdf
                                        </span>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className='mt-2'>
                        <Footer courseLesson={true}/>
                    </div>
                </div>
            ) : (
                <></>
            )}

        </>
    )
}

export default CourseLesson;