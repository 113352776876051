// import React from 'react';

// const VimeoPlayer = ({ videoId }) => {
//     const vimeoUrl = `https://player.vimeo.com/video/${videoId}?autoplay=0`;

//     return (
//         typeof videoId !== 'undefined' && (
//             <div style={{ position: 'relative', overflow: 'hidden', paddingTop: '56.25%' }}>
//                 <iframe
//                     title={`Vimeo Video ${videoId}`}
//                     src={vimeoUrl}
//                     style={{
//                         position: 'absolute',
//                         top: '0',
//                         left: '0',
//                         width: '100%',
//                         height: '100%',
//                         border: '0',
//                     }}
//                     frameBorder="0"
//                     allow="autoplay; fullscreen"
//                     allowFullScreen
//                 ></iframe>
//             </div>
//         )
//     );
// };

// export default VimeoPlayer;


// import React, { useEffect, useRef, useState } from 'react';

// const VimeoPlayer = ({ videoId }) => {
//     const playerRef = useRef(null);
//     const [currentPosition, setCurrentPosition] = useState(0);

//     console.log("currentPosition", currentPosition);

//     useEffect(() => {
//         const iframe = document.getElementById('vimeo-player');
//         if (iframe && window.Vimeo) {
//             const player = new window.Vimeo.Player(iframe, {
//                 id: videoId,
//             });
//             player.on('timeupdate', function (data) {
//                 // const newPosition = data.seconds;
//                 const newPosition = Math.floor(data.seconds);
//                 setCurrentPosition(newPosition);
//                 // console.log('Current position:', newPosition);
//             });
//             playerRef.current = player;
//             return () => {
//                 if (playerRef.current) {
//                     playerRef.current.off('timeupdate');
//                 }
//             };
//         }
//     }, [videoId]);

//     return (
//         typeof videoId !== 'undefined' && (
//             <div style={{ position: 'relative', overflow: 'hidden', paddingTop: '56.25%' }}>
//                 <iframe
//                     id="vimeo-player"
//                     title={`Vimeo Video ${videoId}`}
//                     // src={`https://player.vimeo.com/video/${videoId}?autoplay=0#t=0h01m02s`}
//                     src={`https://player.vimeo.com/video/${videoId}?autoplay=0`}
//                     style={{
//                         position: 'absolute',
//                         top: '0',
//                         left: '0',
//                         width: '100%',
//                         height: '100%',
//                         border: '0',
//                     }}
//                     frameBorder="0"
//                     allow="autoplay; fullscreen"
//                     allowFullScreen
//                 ></iframe>
//             </div>
//         )
//     );
// };

// export default VimeoPlayer;

import React, { useEffect, useRef, useState } from 'react';
import { createCourseLessonPositionOfUserId } from '../../api/CourseApi';
import { useAuth } from '../../context/AuthContext';

const VimeoPlayer = ({ videoId, courseLessonId }) => {
    const playerRef = useRef(null);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [videoEnded, setVideoEnded] = useState(false);

    const [data, setData] = useState(null);

    const { userDetail, token } = useAuth();

    console.log("createcourselessonasperuserid", data);

    useEffect(() => {
        const iframe = document.getElementById('vimeo-player');

        if (iframe && window.Vimeo) {
            const player = new window.Vimeo.Player(iframe, {
                id: videoId,
            });

            // player.on('timeupdate', function (data) {
            //     const newPosition = Math.floor(data.seconds);
            //     setCurrentPosition(newPosition);
            // });

            player.on('ended', function () {
                setVideoEnded(true);
                const postData = async () => {
                    try {
                        const data = await createCourseLessonPositionOfUserId(`user_id=${userDetail.id}&course_lesson_id=${courseLessonId}`, token);
                        setData(data);
                    } catch (error) {
                        console.log("error", error);
                    }
                };

                postData();
            });

            playerRef.current = player;

            // return () => {
            //     if (playerRef.current) {
            //         playerRef.current.off('timeupdate');

            //         playerRef.current.off('ended');
            //     }
            // };
        }
    }, [videoId, videoEnded]);

    console.log("videoEnded", videoEnded);

    // return (
    //     typeof videoId !== 'undefined' && (
    //         <div style={{ position: 'relative', overflow: 'hidden', paddingTop: '56.25%', height: "" }}>
    //             <iframe
    //                 id="vimeo-player"
    //                 title={`Vimeo Video ${videoId}`}
    //                 src={`https://player.vimeo.com/video/${videoId}?autoplay=0`}
    //                 style={{
    //                     position: 'absolute',
    //                     top: '0',
    //                     left: '0',
    //                     width: '100%',
    //                     height: '100%',
    //                     border: '0',
    //                 }}
    //                 frameBorder="0"
    //                 allow="autoplay; fullscreen"
    //                 allowFullScreen
    //             ></iframe>
    //         </div>
    //     )
    // );

    return (
        typeof videoId !== 'undefined' && (
          <div style={{ position: 'relative', paddingTop: '56.25%', height: "" }}>
            <iframe
              id="vimeo-player"
              title={`Vimeo Video ${videoId}`}
              src={`https://player.vimeo.com/video/${videoId}?autoplay=0`}
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                border: '0',
              }}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </div>
        )
      );
      
};

export default VimeoPlayer;


