import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VimeoPlayerDuration = ({ videoId }) => {
  const [videoDuration, setVideoDuration] = useState(null);

  useEffect(() => {
    const fetchVideoInfo = async () => {
      try {
        // Replace 'YOUR_ACCESS_TOKEN' with your actual Vimeo API access token
        const accessToken = '0ad540131decf2ec9f6a21728d748551';

        const response = await axios.get(`https://api.vimeo.com/videos/${videoId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // Extract the duration from the response
        const durationInSeconds = response.data.duration;

        setVideoDuration(durationInSeconds);
      } catch (error) {
        console.error('Error fetching Vimeo video info:', error);
      }
    };

    if (videoId) {
      fetchVideoInfo();
    }
  }, [videoId]);

  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    return `${minutes}:${seconds}`;
  };

  return (
    typeof videoId !== 'undefined' && (
      <span>
        {videoDuration !== null && (
          <span className=''>{formatDuration(videoDuration)}</span>
        )}
      </span>
    )
  );
};

export default VimeoPlayerDuration;
