
import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table } from 'react-bootstrap';
import CommonNavbar from './component/common/Navbar'
import Footer from './component/common/Footer';
import { getPageDetails } from './api/CourseApi';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';


const Home = () => {
    const [faqCurrentIndex, setFaqCurrentIndex] = useState();

    const frontBaseUrl = process.env.REACT_APP_BASE_URL;

    const [Data, setData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPageDetails('home');
                setData(data);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    const imageURL = process.env.REACT_APP_CMS_IMAGE_URL;

    const Banner = Data && Data.page_section && Data.page_section.banner;
    const BannerBelow = Data && Data.page_section && Data.page_section.bannerbelow;
    const Card = Data && Data.page_section && Data.page_section.card.card.attributes;
    const FirstEmailSection = Data && Data.page_section && Data.page_section.firstemailsection;
    const SecondEmailSection = Data && Data.page_section && Data.page_section.secondemailsection;
    const Instagram = Data && Data.page_section && Data.page_section.instagramcard;
    const InstagramCard = Data && Data.page_section && Data.page_section.instagramcard.card.attributes;
    const FAQ = Data && Data.page_section && Data.page_section.faqs;
    const FAQList = Data && Data.page_section && Data.page_section.faqs.faqs.attributes;

    const homeBanner = {
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundBlendMode: 'normal',
        backgroundRepeat: 'no-repeat',
        backgroundImage: Banner ? `linear-gradient(to bottom, rgba(34, 84, 211, 0.25) 0%, rgba(34, 84, 211, 0.25) 100%), url("${imageURL}/${Banner.image.value}")` : 'none',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Email id is required'),
    });

    const initialValues = {
        email: '',
    };

    const onSubmit = async (values, { resetForm }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}common-send-mail`, values);
            if (response.status === 200) {
                resetForm();
                alert('Email sent successfully');
            } else {
                alert('Failed to send email');
            }
        } catch (error) {
            alert('An error occurred');
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });


    const validationTrainSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Email id is required'),
    });

    const initialTrainValues = {
        email: '',
    };

    const onSubmitTrain = async (values, { resetForm }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}common-send-mail`, values);
            if (response.status === 200) {
                resetForm();
                alert('Email sent successfully');
            } else {
                alert('Failed to send email');
            }
        } catch (error) {
            alert('An error occurred');
        }
    };

    const formikTrain = useFormik({
        initialValues: initialTrainValues,
        validationSchema: validationTrainSchema,
        onSubmit: onSubmitTrain,
    });


    return (
        <>
            {Banner && (
                <div className='home-banner' style={homeBanner}>
                    <CommonNavbar />
                    <Container className='mt-5'>
                        <div className='home-banner-basic'>
                            <center>
                                <h1 className='banner-home-heading pt-5'>{parse(String(Banner.title.value))}</h1>
                                <button className='mt-3 mb-5'>
                                    <Link to={`${Banner.button_link.value}`}>
                                        {Banner.button_name.value}
                                    </Link>
                                </button>
                            </center>
                        </div>

                    </Container>
                    {BannerBelow && (
                        <div className='banner-bottom'>
                            <h2>{parse(String(BannerBelow.description.value))}</h2>
                        </div>
                    )}

                </div>
            )}


            <div className='home'>
                <Container>
                    <div className='web-info-card'>
                        <center>
                            <Row>
                                {Card && (
                                    Card.map((obj, index) => (
                                        <Col lg={4} className='mt-4' key={index}>
                                            <img src={`${imageURL}/${obj.image.value}`} alt={index} />
                                            <h4 className='mt-3'>{obj.title.value}</h4>
                                            <p>{parse(String(obj.description.value))}</p>
                                            <button>
                                                <Link to={`${obj.button_link.value}`}>{obj.button_name.value}</Link>
                                            </button>
                                        </Col>
                                    ))
                                )}
                            </Row>
                        </center>
                    </div>
                </Container>
                {FirstEmailSection && (
                    <div className='email'>
                        <Container>
                            <center>
                                <h2>{FirstEmailSection.title.value}</h2>
                                <p>
                                    {parse(String(FirstEmailSection.description.value))}
                                </p>
                                <form onSubmit={formikTrain.handleSubmit}>
                                    <Row className='e-form'>
                                        <Col lg={9}>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="email"
                                                    className={`form-control`}
                                                    id="floatingInput"
                                                    placeholder="name@example.com"
                                                    {...formikTrain.getFieldProps('email')}
                                                    autoComplete="off"
                                                />
                                                <label htmlFor="floatingInput">Email</label>
                                                {formikTrain.touched.email && formikTrain.errors.email && (
                                                    <div className="error text-warning fw-bold mt-1 text-start">{formikTrain.errors.email}</div>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <button type="submit" disabled={formikTrain.isSubmitting}>
                                                {formikTrain.isSubmitting ? <a className='text-secondary'>Wait...</a> : <a> Allenati</a>}
                                            </button>
                                        </Col>
                                    </Row>
                                </form>
                            </center>
                        </Container>
                    </div>
                )}

                <div className='soon'>
                    <Container>
                        <center>
                            <div className='post'>
                                <span>
                                    I post saranno presto disponibili.
                                </span>
                            </div>
                        </center>
                    </Container>
                </div>

                {FAQ && (
                    <Container>
                        <div className='faqs mb-5'>
                            <h2 className='mt-4 mb-5'>
                                {FAQ.title.value}
                            </h2>
                            {FAQList && (
                                <Table>
                                    {FAQList.map((obj, index) =>
                                        <tr className="table-row" key={`Faq_List_${index}`}>
                                            <td>
                                                {faqCurrentIndex == index ? <h3 style={{ color: "rgb(34, 84, 211)" }} onClick={() => setFaqCurrentIndex(index)}>{obj.question.value}</h3> : <h3 onClick={() => setFaqCurrentIndex(index)}>{obj.question.value}</h3>}
                                                {faqCurrentIndex == index ?
                                                    <p
                                                        dangerouslySetInnerHTML={
                                                            {
                                                                __html:
                                                                    `${obj.answer.value}`
                                                            }
                                                        }
                                                    >
                                                    </p> :
                                                    <></>
                                                }

                                            </td>
                                        </tr>
                                    )}
                                </Table>
                            )}
                        </div>
                    </Container>
                )}


                {Instagram && (
                    <div className='instagram mt-5'>
                        <Container>
                            <h2>
                                {Instagram.title.value}
                            </h2>
                            <Row className='mt-5 mb-5'>
                                {InstagramCard && (
                                    InstagramCard.map((obj, index) =>
                                        <Col lg={3} className='mt-3' key={`insta_card_${index}`}>
                                            <a href={`${obj.link.value}`} target='_blank'>
                                                <div className='InstaPost'
                                                    style={{ backgroundImage: `url(${imageURL}/${obj.image.value})` }}
                                                >
                                                    <svg viewBox="0 0 24 24" fill="currentColor" width="48" height="48" data-ux="Icon" className="x-el x-el-svg c2-1 c2-2 c2-16 c2-1u c2-s c2-6o c2-3 c2-4 c2-5 c2-6b c2-6c c2-6d c2-6e c2-6f c2-6g c2-6h c2-6i c2-6 c2-7 c2-8"><path d="M16.604 8.516c.13.35.198.719.203 1.091.033.622.033.811.033 2.386 0 1.574-.004 1.763-.033 2.385a3.273 3.273 0 0 1-.203 1.091 1.956 1.956 0 0 1-1.12 1.12c-.35.13-.719.198-1.091.204-.622.032-.811.032-2.386.032-1.574 0-1.763-.003-2.385-.032a3.273 3.273 0 0 1-1.091-.204 1.956 1.956 0 0 1-1.12-1.12 3.273 3.273 0 0 1-.204-1.09c-.032-.623-.032-.812-.032-2.386 0-1.575.003-1.764.032-2.386.006-.372.074-.741.204-1.09a1.956 1.956 0 0 1 1.12-1.12c.35-.13.718-.199 1.09-.204.623-.033.812-.033 2.386-.033 1.575 0 1.764.004 2.386.033.372.005.741.074 1.09.203.515.2.922.606 1.12 1.12zM12 15.033a3.033 3.033 0 1 0 0-6.066 3.033 3.033 0 0 0 0 6.066zm3.153-5.477a.71.71 0 1 0 0-1.418.71.71 0 0 0 0 1.418zM12 13.967a1.967 1.967 0 1 1 0-3.934 1.967 1.967 0 0 1 0 3.934zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 12 2zm5.87 12.433c-.01.49-.102.974-.274 1.432a3.018 3.018 0 0 1-1.727 1.728 4.335 4.335 0 0 1-1.433.272c-.629.03-.829.037-2.432.037-1.604 0-1.819 0-2.433-.037a4.335 4.335 0 0 1-1.433-.272 3.018 3.018 0 0 1-1.727-1.728 4.335 4.335 0 0 1-.273-1.432c-.029-.63-.036-.83-.036-2.433 0-1.604 0-1.818.036-2.433.01-.49.102-.974.273-1.432a3.018 3.018 0 0 1 1.727-1.728 4.335 4.335 0 0 1 1.433-.272c.629-.03.829-.037 2.433-.037 1.603 0 1.818 0 2.432.037.49.009.974.101 1.433.272.794.307 1.42.934 1.727 1.728.172.458.264.943.273 1.432.03.63.036.83.036 2.433 0 1.604-.007 1.804-.036 2.433z"></path></svg>
                                                </div>
                                            </a>
                                        </Col>
                                    )
                                )}
                                <center>
                                    <div className='instagram-link mt-5'>
                                        <a href={Instagram.link.value} className='text-decoration-none'>
                                            {Instagram.link_name.value}
                                            <svg viewBox="0 0 24 24" fill="currentColor" width="1em" height="1em" data-ux="Icon" data-aid="chevronRight" className="x-el x-el-svg c2-1 c2-2 c2-16 c2-r c2-s c2-3 c2-4 c2-5 c2-6 c2-7 c2-8"><path fillRule="evenodd" d="M7.861 4.125c-.335-.293-.84-.039-.84.424L7 19.362c0 .446.481.697.811.424l8.693-7.203a.56.56 0 0 0 .011-.836L7.861 4.125z"></path></svg>
                                        </a>
                                    </div>
                                </center>
                            </Row>
                        </Container>
                    </div>
                )}

                {SecondEmailSection && (
                    <div className='email-register'>
                        <Container>
                            <center>
                                <h2>{SecondEmailSection.title.value}</h2>
                                <p>
                                    {parse(String(SecondEmailSection.description.value))}
                                </p>
                                <form onSubmit={formik.handleSubmit}>
                                    <Row className='e-r-form'>
                                        <Col lg={9}>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="email"
                                                    className={`form-control`}
                                                    id="floatingInput"
                                                    placeholder="name@example.com"
                                                    {...formik.getFieldProps('email')}
                                                    autoComplete="off"
                                                />
                                                <label htmlFor="floatingInput">Email</label>
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className="error text-warning fw-bold mt-1 text-start">{formik.errors.email}</div>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <button type="submit" disabled={formik.isSubmitting}>
                                                {formik.isSubmitting ? <a className='text-secondary'>Wait...</a> : <a>Registrati</a>}
                                            </button>
                                        </Col>
                                    </Row>
                                </form>
                            </center>
                        </Container>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Home;