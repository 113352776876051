import React, { useState, useEffect } from 'react';
import { getPageDetails } from '../../api/CourseApi';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';


const Footer = ({ courseLesson }) => {
    const [Data, setData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPageDetails('footer');
                setData(data);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    const FooterData = Data && Data.page_section && Data.page_section.footersection;

    return (
        <>
            {FooterData && (
                <footer className={`${typeof courseLesson != 'undefined' ? "pt-3 pb-3" : ""}`}>
                    <center>
                        <p className='mb-4'>
                            {parse(String(FooterData.copyright_title.value))}
                        </p>
                        <Link to={`${FooterData.link.value}`}>
                            {parse(String(FooterData.link_name.value))}
                        </Link>
                        {/* <a href={`${process.env.REACT_APP_BASE_URL}${FooterData.link.value}`} className='mb-4'>
                            {parse(String(FooterData.link_name.value))}
                        </a> */}
                    </center>
                </footer>
            )}

        </>
    )
}

export default Footer;