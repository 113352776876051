import React, { useEffect, useState } from 'react';
import CommonNavbar from './component/common/Navbar';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { login } from './api/AuthApi';
import { verifyToken } from './api/AuthApi';
import { ToastComponent } from './component/common/ToastComponent';
import Footer from './component/common/Footer';

const Login = () => {
    const { login: authLogin, verifyToken: authVerifyToken, userDetail } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    console.log("userDetail", userDetail);

    const handleLogin = async (e) => {
        e.preventDefault(); // Prevents the default form submission behavior

        try {
            const token = await login(email, password);
            authLogin(token);

            const data = await verifyToken(token);
            authVerifyToken(data.user);

            if (data?.status == true) {
                setShow(true);
                setToastText({
                    status: "success",
                    text: "User Logged in successfully"
                })
                setMessage("User Logged in successfully");
                setTimeout(() => {
                    navigate(`/`);
                }, 2000);
            }
            else {
                setShow(true);
                setMessage(data?.message);
                setToastText({
                    status: "danger",
                    text: data?.message
                })
            }

        } catch (error) {
            console.error('Login failed:', error.message);
            setShow(true);
            setMessage("Login failed");
            setToastText({
                status: "danger",
                text: "Login failed"
            })
        }
    };

    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [toastText, setToastText] = useState({
        status: "danger",
        text: "error"
    });


    const handleChange = (value) => {
        setShow(value);
    };


    return (
        <>
            <CommonNavbar />
            <ToastComponent bg={toastText.status} title={toastText.text} msg={message} show={show} onChange={handleChange} />

            <div className='login mb-5 mt-5'>
                <Container>
                    <center>
                        <h2>
                            Accesso all’account
                        </h2>
                        <form onSubmit={handleLogin}>
                            <p className='mt-5'>
                                Entra nel tuo account per accedere al tuo profilo, alla cronologia e a eventuali pagine private a cui ti è stato consentito l’accesso.
                            </p>
                            <div className="form-group">
                                <input type="email" className="form-control mt-4" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <input type="password" className="form-control mt-4" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                {/* <input type="password" className="form-control mt-3" id="exampleInputPassword1" placeholder="Password" /> */}
                            </div>
                            <button className='mt-3' type="submit">
                                <span>ENTRA</span>
                            </button>
                            <p className='mt-3'><a href="">Reimposta password</a></p>
                            <div className='mt-4'>
                                <p>Non sei un membro? <a href="/create-account">Crea l’account.</a> </p>
                            </div>
                        </form>
                    </center>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default Login;